.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-body {
    background-color: #262a2e;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
}

.App-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.instructions-container {
    text-align: left;
    max-width: 800px;
    margin: 20px auto 0;
}

@media (max-width: 800px) {
    .instructions-container {
        max-width: 90%;
    }
}

.instructions-container h3 {
    color: #333;
}

.instructions-container ol {
    list-style: decimal;
    padding-left: 20px;
}

.instructions-container li {
    margin-bottom: 10px;
}

.instructions-container a {
    color: #007bff;
    text-decoration: none;
}

.instructions-container a:hover,
.instructions-container a:hover span {
    text-decoration: underline;
}

.instructions-container code {
    color: yellow;
}

.damage-done-container {
    text-align: center;
    width: 1000px;
    background: #141414;
    border: 3px solid grey;
    border-radius: 1px;
    padding: 15px;
    margin-bottom: 10px;
}

@media (max-width: 1050px) {
    .damage-done-container {
        text-align: center;
        width: 90vw;
        background: #141414;
        border: 3px solid grey;
        border-radius: 1px;
        padding: 15px;
        margin-bottom: 15px;
    }
}

.logs-box {
    color: #e1f2f5;
    width: 1036px;
    background: black;
    margin-bottom: 80px;
}

@media (max-width: 1050px) {
    .logs-box {
        max-width: 95vw;
    }
}

.logs-box table {
    border-collapse: collapse;
}

.logs-box th,
.logs-box td {
    border: 1px solid grey;
    color: white;
    font-size: calc(13px);
    text-align: left;
    line-height: 20px;
    whiteSpace: 'normal';
    wordWrap: 'break-word';
    font-family: Avenir, Arial, sans-serif !important;
}


.MuiTableCell-root {
    padding-left: 6px !important;
    padding-right: 6px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.logs-text {
    color: #e5cc80 !important;
}

.even-row {
    background-color: #101010;
}

.odd-row {

}

.damage-amount {
    color: rgb(92%, 27%, 38%);
}

.heal-amount {
    color: rgb(0, 255, 4);
}

.hitsplat-name {
    color: #e5cc80;
}

.attack-animation-text {
    color: #abd473 ;
}

.logged-in-player-text {
    color: #abd473 !important;
}

.other-text {
    color: #b4bdff !important;
}

.unknown-text {
    color: rgb(92%, 27%, 38%);
}

.dps-text {
    color: #d1fa99 !important;
}

.highlighted-row {
    background-color: rgb(0, 32, 64);
}

.recharts-cartesian-axis .recharts-text {
    font-size: calc(17px + 0.5vmin);
}

.recharts-cartesian-axis-ticks, #recharts_measurement_span {
    @apply text-sm;
}

.recharts-pie-label-text {
    font-size: calc(12px + 0.5vmin);
}

.loading-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.combobox-hover {
    background: lightgray;
}

.combobox-input {
    width: 240px;
    height: 30px;
    text-align: left;
    font-size: 18px;
    padding-left: 8px;
}

.combobox {
    background: white;
    color: black;
    font-size: 18px;
    z-index: 999;
    position: absolute;
    text-align: left;
    padding-left: 8px;
}

.MuiTooltip-tooltip.MuiTooltip-tooltip {
    background: #101010;
    padding: 5px 8px;
    border: 4px solid grey;
    border-radius: 1px;
    font-size: 18px;
    text-align: left;
    color: white;
}

.recharts-tooltip-wrapper {
    background: #101010;
    padding: 5px 8px;
    border: 4px solid grey;
    border-radius: 1px;
    font-size: 18px;
    text-align: left;
    color: white;
}

.results-container th,
.results-container td {
    border: 1px solid grey;
    color: white;
    font-size: calc(20px);
    text-align: left;
    line-height: 30px;
    whiteSpace: 'normal';
    wordWrap: 'break-word';
    font-family: Avenir, Arial, sans-serif !important;
}


.MuiAppBar-root .MuiToolbar-root {
    min-height: auto !important;
    height: 50px;
}

.dismiss-button {
    color: white !important;
    font-family: Avenir, Arial, sans-serif !important;
}

.fight-container {
    position: relative;
    border: 1px solid black;
    background-color: #262a2e;
    width: 160px;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
}

.fight-container:hover {
    border-color: white;
}

.raid-fight-container {
    position: relative;
    border: 1px solid black;
    background-color: #262a2e;
    width: 300px;
    height: 40px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
}

.raid-fight-container:hover {
    border-color: white;
}

.fight-title {
    font-size: 18px;
}

.banner {
    width: 100%;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-bottom: 10px;
}

.banner p {
    margin: 0;
    padding: 10px;
    font-size: 18px;
    text-align: left;
    font-family: Avenir, Arial, sans-serif !important;
}

.fight-list {
    display: flex;
    flex-wrap: wrap;
}

.back-icon-wrapper {
    cursor: pointer;
    margin-right: 10px;
    font-size: 35px;
    border-radius: 50%; /* To make it circular */
    width: 40px;
    height: 40px;
    transition: background-color 0.3s;
}

.back-icon-wrapper:hover {
    background-color: grey;
}

.gold-star {
    position: absolute;
    top: 25%;
    right: 10%;
    font-size: 24px;
    color: gold;
}

.performance-container {
    position: relative;
    border: 1px solid black;
    background-color: #141414;
    border: 3px solid grey;
    border-radius: 1px;
    padding: 15px;
    margin-bottom: 10px;
    width: 160px;
    height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
}

.performance-percent {
    font-size: 45px;
}

.tan-switch .MuiSwitch-thumb {
    color: tan !important;
}

.tan-switch .MuiSwitch-track {
    background-color: tan !important;
}

.link {
    text-decoration: none;
    color: inherit;
}

.link:hover {
    text-decoration: underline;
}

.dropdown-fight-selector {
    background-color: #141414;
    border: 3px solid grey;
    color: white;
    font-size: calc(8px + 1vmin);
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Map Container Styling */
.leaflet-container {
    background: #000;
    z-index: 0 !important;
}

/* Playback Controls Styling */
.playback-controls {
    position: relative;
    bottom: 0px;
    left: 0;
    background: #262a2e;
    color: white;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.playback-controls button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.playback-controls input[type='range'] {
    flex: 1;
    margin: 0px 15px;
}

.playback-controls span {
    white-space: nowrap;
}

.play-pause-button svg {
    fill: white;
}

.play-pause-button svg:hover {
    fill: #ccc;
}

.mouse-hover-tooltip {
    position: absolute;
    pointer-events: none;
    background-color: rgba(100, 100, 100, 0.8);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
}

